import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Ad = () => {
  const title = '詠春成人男女班';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <p>
        <strong className="red">
          開課後仍然繼續招生，歡迎參觀或免費試學一堂 (無附帶條件)。
        </strong>
      </p>

      <p>
        宗旨: 教授最純正 (<strong className="red">葉問—梁相</strong>)
        詠春拳，對自衛、強身 (纖體)
        及提升智力極有幫助。我們曾舉辦非常成功的「學詠春、學英文」計劃，得到四份報章專題報導
        (詳情可按入 <Link to="/reactions/media">Media Publications 網頁</Link>
        )。
      </p>

      <p>課程: 小念頭、尋橋、標指、黐手、木人樁、六點半棍、八斬刀。</p>

      <p>
        導師:
        <br /> <strong className="red">江覺峰師父</strong>—師從梁相恩師
        (葉問宗師大弟子、李小龍大師兄)
        多年，曾任梁相詠春國術會主席，有三十年詠春學習及教授經驗。
        <br /> <strong className="red">車世杰師父</strong>
        —十二歲起師從梁相恩師多年，有「詠春神童」之稱，詠春功力及技術極為超卓。
      </p>

      <p>
        <strong className="red">詠春成人男女班</strong>：<br /> 參加者
        <strong>最好</strong>為年齡由十八歲至五十五歲之男士或女士
      </p>

      <p>
        授課時間:
        <br /> <strong>初級班</strong>
      </p>

      <p>
        <strong>旺角砵蘭街329號3樓 (貝爾窗簾樓上)</strong>
      </p>

      <p>逢星期二下午七時半至九時半 (招生)</p>

      <p>
        <strong>中級班：</strong>
      </p>
      <p>
        <strong>旺角砵蘭街329號3樓 (貝爾窗簾樓上)</strong>
      </p>

      <p>逢星期五晚上七時半至九時半 (招生)</p>

      <p>
        <strong>中級班：</strong>
      </p>
      <p>
        <strong>旺角砵蘭街329號3樓 (貝爾窗簾樓上)</strong>
      </p>

      <p>逢星期六早上十時至十二時 (招生)</p>
      <p>學費: 初級班每月 500元。中級班每月 500元。</p>
      <p>有意參加者可於上課時間即場報名參加我們的課程。</p>
      <p>
        查詢電話: <strong className="red">97662217</strong> (江覺峰師父)
      </p>
    </Layout>
  );
};

export default Ad;
